<template>
	<div ref="box">
		<div class="mt30" v-if="loading || list.length">
		<el-skeleton 
			class="vue-waterfall"
			:loading="loading && !list.length"
			animated
			:count="colNum == '贴图专辑' ? 3 : 5"
			
			>
			
				<template slot="template">
					<div v-if="colNum == '贴图专辑'" style="width: 33.3%;">
						<div class="cell-item-3">
							<div class="itemBox-3">
								<div class="img-3" v-for="i in 5" :key="i">
									<el-skeleton-item class="image" variant="image" />
								</div>
							</div>
						</div>
					</div>
					<div v-else style="width: 20%;">
						<div class="cell-item">
							<div class="itemBox">
								<el-skeleton-item class="cell-item-5-img" variant="image" />
							</div>
						</div>
						<el-skeleton-item style="width: calc(100% - 0.24rem); height: 0.14rem; margin: 0 0.12rem 0.32rem;" class="item-5-Name txt-over" variant="text" />
					</div>
				</template>
				<waterfall :col="colNum == '贴图专辑' ? 3 : 5" :data="list" :gutterWidth="0" @loadmore="loadmore">
					<template v-if="colNum == '贴图专辑'">
						<div class="cell-item-3 commonItemShadowHover" v-for="item in list" :key="item.id">
									<router-link target="_blank" :to="{ path: '/chartlet/album', query: {detailId: item.id}}">
							<div class="itemBox-3">
								<template v-if="item&&item.pic_link">
										<div class="img-3" v-for="(i, index) in item.pic_link" :key="index">
											<img oncontextmenu="return false" v-if="i" :src="i" alt="加载错误"/>
										</div>
								</template>
							</div>
							<div class="itemBox-3-name flex-sb">
								<div class="txt-over">{{item&&item.title}}</div>
								<div v-if="item&&item.pic_num" class="textBold mr10">+{{item&&item.pic_num}}</div>
							</div>
									</router-link>
						</div>
					</template>
					<template v-else>
						<div class="commonItemHover" v-for="item in list" :key="item.id">
							<div class="cell-item cell-item-5">
								<router-link target="_blank" :to="{ path: '/chartlet/detail', query: {detailId: item.id}}">
									<div class="itemBox">
										<img oncontextmenu="return false" class="cell-item-5-img" v-if="item&&item.pic_str" :src="item&&item.pic_str" alt="加载错误" />
									</div>
								</router-link>
								
								<div class="item-tag">
									<img v-if="item&&item.type == 1" src="../../../assets/image/chartlet/vip-tag.png" alt="">
									<img v-if="item&&item.type == 2" src="../../../assets/image/chartlet/brand-tag.png" alt="">
									<img v-if="item&&item.type == 3" src="../../../assets/image/chartlet/seamless-tag.png" alt="">
								</div>
							</div>
							<div class="item-5-Name txt-over">{{item&&item.title}}</div>
						</div>
					</template>
				</waterfall>
				<div style="height: 40px;">
					<img class="loadImg" :class="{ loadImgs: !loading }" src="@/assets/image/loading.gif" alt="">
					<p class="loadText" :class="{ loadTexts: !loading && !listStatus.isData }">
						没有更多了...
					</p>
				</div>
			</el-skeleton>
		</div>
		<div v-else>
			<el-empty description="没有内容哦"></el-empty>
		</div>
	</div>
</template>
<script>
export default {
  name: "ChartletList",
	props: {
		chartletObj: {
			type: [Array, Object],
		},
		colNum: {
			type: [Number, String],
			default: 2
		},
		loading: {
			type: Boolean,
			default: false
		},
		listStatus: {
			type: Object,
			default: () => {
				return {
					isData: true,	// 判断是否有下一页
					isNull: false	// 判断是否为空
				}
			}
		}
	},
  data() {
    return {
      list: [],
			timer: null
    };
  },
  computed: {},
	methods: {
		clear() {
			this.list = [];
		},
		loadmore() {
			if (this.listStatus.isNull || !this.listStatus.isData) return;
			this.$emit("nextPage");
		},
		pushData() {
			if (this.chartletObj.data && this.chartletObj.current_page > 1) {
				this.list = this.list.concat(this.chartletObj.data);
			} else if (this.chartletObj.data && this.chartletObj.data.length){
				this.list = this.chartletObj.data
			}
		},
		fnThrottle (method, delay, duration) {
			var that = this;
			var timer = this.timer;
			var begin = new Date().getTime();
			return function(){
				var context = that;
				var args = arguments;
				var current = new Date().getTime();
				clearTimeout(timer);
				if(current-begin>=duration){
					method.apply(context,args);
					begin=current;
				}else{
					that.timer=setTimeout(function(){
						method.apply(context,args);
					}, delay);
				}
			}
		},
		handleScroll() {
			const scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			const windowHeight =
				document.documentElement.clientHeight || document.body.clientHeight;
			const offsetTop = this.$refs.box.offsetHeight;
			if (scrollTop + windowHeight >= offsetTop && !this.loading) {
				//到了这个就可以进行业务逻辑加载后台数据了
				if (this.listStatus.isNull || !this.listStatus.isData) return;
				this.fnThrottle(this.loadmore, 150, 150)()
			}
		},
		jump(url, item) {
			const {href} = this.$router.resolve({ path: url, query:{detailId: item.id, name: item.title}});
			window.open(href, '_blank')
		}
	},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    chartletObj() {
			this.pushData();
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
}
.loadImgs {
	height: 0;
}
.loadText {
	height: 0;
	line-height: 40px;
	overflow: hidden;
	text-align: center;
	transition: 0.5s;
	color: black;
}
.loadTexts.loadText {
	height: auto;
	display: block;
}
.loadImg {
	display: block;
	margin: 0 auto;
	color: black;
  transition: 0.5s;
	text-align: center;
}
.cell-item {
  position: relative;
  cursor: pointer;
	width: 100%;
	padding-bottom: 75%;
	margin-bottom: 0.1rem;
}
.cell-item-5 {
  position: relative;
  cursor: pointer;
	width: 100%;
	padding-bottom: calc(75% - 0.18rem );
	margin-bottom: 0.1rem;
}
.itemBox {
	position: absolute;
	top: 0;
	right: 0.12rem;
	bottom: 0;
	left: 0.12rem;
	overflow: hidden;
	background-color: #fff;
	border-radius: 0.04rem;
}

.item-tag {
	position: absolute;
	top: 0.16rem;
	left: 0.28rem;
	width: 0.32rem;
	height: 0.42rem;
}

.item-tag img{
	width: 0.32rem;
	height: 0.42rem;
}


.cell-item-3 {
	position: relative;
	background-color: #fff;
	margin: 0 0.12rem 0.32rem;
	border-radius: 0.04rem;
	overflow: hidden;
}
.itemBox-3 {
	font-size: 0;
	padding: 0.1rem 0 0 0.1rem;
}
.itemBox-3-name {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 0.56rem;
	line-height: 0.56rem;
	background: linear-gradient(180deg, rgba(204, 204, 204, 0) 0%, rgba(128, 128, 128, 0) 0%, rgba(128, 128, 128, 0.9) 100%, rgba(128, 128, 128, 0.9) 100%);
	padding: 0 0.1rem;
	font-size: 0.18rem;
	font-weight: 500;
	color: rgba(255, 255, 255, 1);
}
.itemBox-3>div{
	position: relative;
	display: inline-block;
	margin: 0 0.1rem 0.1rem 0;
	border-radius: 0.04rem;
	object-fit: cover;
	font-size: 0;
}
.itemBox-3 img,.itemBox-3 .image{
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100% !important;
	height: 100% !important;
	border-radius: 0.04rem;
	object-fit: cover;
}
.img-3 {
	width: calc(50% - 0.1rem);
	padding-bottom: calc(37.5% - 0.075rem);
}
.img-3:nth-child(n+3) {
	width: calc(33.3% - 0.1rem);
	padding-bottom: calc(24.97% - 0.075rem);
}


.cell-item-5-img {
	width: 100%;
	height: 100% !important;
	object-fit: cover;
}
.item-5-Name {
	padding: 0 0.12rem;
	margin-bottom: 0.32rem;
	font-size: 0.14rem;
	font-weight: 500;
	color: rgba(56, 56, 56, 1);
}
.vue-waterfall {
  width: calc(100% + 0.12rem);
	padding-top: 6px;
	margin: 0 -0.06rem;
	overflow-x: hidden;
}
.vue-waterfall::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.vue-waterfall::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>